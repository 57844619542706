<template>
  <div class="search-clients-page" @click="handleOutsideClick">
    <HomeIcon :showArrow="true" to="employee-dashboard" />
    <div class="container">
      <h3>Pesquisar Clientes</h3>
      <div class="search-section">
        <input
          v-model="searchQuery"
          @input="onInput"
          @focus="showDropdown = true"
          placeholder="Digite o nome ou email do cliente"
          class="search-input"
        />

        <!-- Loader para a busca de clientes -->
        <div v-if="loadingClients" class="loader-container">
          <div class="loader"></div>
          <p>Carregando dados...</p>
        </div>

        <!-- Dropdown de Clientes -->
        <ul
          v-if="showDropdown && !loadingClients && filteredClients.length > 0"
          class="client-dropdown"
        >
          <li
            v-for="client in filteredClients"
            :key="client.id"
            @click.stop="selectClient(client)"
          >
            {{ client.nome }} - {{ client.email }}
          </li>
        </ul>

        <!-- Mensagem de "Nenhum cliente encontrado" -->
        <p
          v-if="!loadingClients && searchQuery.trim().length > 0 && filteredClients.length === 0"
          class="no-results"
        >
          Nenhum cliente encontrado.
        </p>
      </div>

      <!-- Loader para os detalhes do cliente -->
      <div v-if="loadingDetails" class="loader-container">
        <div class="loader"></div>
        <p>Carregando dados...</p>
      </div>

      <!-- Detalhes do cliente -->
      <div v-if="selectedClient && !loadingDetails" class="client-details">
        <h4>Detalhes do Cliente: {{ selectedClient.nome }}</h4>
        <p><strong>Email:</strong> {{ selectedClient.email }}</p>
        <p><strong>Telefone:</strong> {{ selectedClient.telefone }}</p>
        <p><strong>CPF:</strong> {{ selectedClient.cpf }}</p>

        <!-- Seção de Planos Ativos -->
        <div class="client-section">
          <h5>Planos Ativos:</h5>
          <ul v-if="selectedClient.planos && selectedClient.planos.length > 0">
            <li v-for="plano in selectedClient.planos" :key="plano.id">
              {{ plano.nome }}: {{ formatDate(plano.inicio) }} - {{ formatDate(plano.fim) }}
            </li>
          </ul>
          <p v-else class="empty-info">Nenhum plano ativo encontrado.</p>
        </div>

        <!-- Seção de Horários Reservados -->
        <div class="client-section">
          <h5>Horários Reservados:</h5>
          <ul v-if="filteredHorarios.length > 0">
            <li v-for="horario in filteredHorarios" :key="horario.id">
              {{ formatDate(horario.data) }} {{ horario.horario }} - {{ horario.quadra.nome }}
            </li>
          </ul>
          <p v-else class="empty-info">Nenhum horário reservado encontrado.</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, computed } from "vue";
import axios from "axios";
import dayjs from "dayjs";
import HomeIcon from "../../components/HomeIcon.vue";

export default defineComponent({
  name: "SearchClients",
  components: { HomeIcon },
  setup() {
    const searchQuery = ref<string>("");
    const clients = ref<any[]>([]);
    const selectedClient = ref<any | null>(null);
    const loadingClients = ref(false);
    const loadingDetails = ref(false);
    const showDropdown = ref(false);
    const debounceTimeout = ref<ReturnType<typeof setTimeout> | null>(null);

    // Função para buscar clientes
    const searchClients = async () => {
      loadingClients.value = true;
      try {
        const response = await axios.get(
          `${process.env.VUE_APP_BACKEND_URL}/searchClients?query=${searchQuery.value}`
        );
        clients.value = response.data;
      } catch (error) {
        console.error("Erro ao buscar clientes:", error);
      } finally {
        loadingClients.value = false;
      }
    };

    // Função para controlar o debounce da busca
    const onInput = () => {
      if (debounceTimeout.value) clearTimeout(debounceTimeout.value);
      debounceTimeout.value = setTimeout(async () => {
        if (searchQuery.value.trim() === "") {
          clients.value = [];
          return;
        }
        await searchClients();
      }, 300); // Debounce de 300ms
    };

    // Função para selecionar um cliente e buscar seus detalhes
    const selectClient = async (client: any) => {
      selectedClient.value = client;
      searchQuery.value = "";
      clients.value = [];
      showDropdown.value = false;
      loadingDetails.value = true;

      try {
        const response = await axios.get(
          `${process.env.VUE_APP_BACKEND_URL}/horariosOcupados/${client.id}`
        );
        selectedClient.value = {
          ...client,
          horarios: response.data,
          planos: response.data.planos,
        };
      } catch (error) {
        console.error("Erro ao buscar detalhes do cliente:", error);
      } finally {
        loadingDetails.value = false;
      }
    };

    // Filtra os horários futuros do cliente
    const filteredHorarios = computed(() => {
      if (!selectedClient.value || !selectedClient.value.horarios) {
        return [];
      }

      const now = dayjs();
      return selectedClient.value.horarios.filter((horario: any) => {
        const horarioDateTime = dayjs(`${horario.data} ${horario.horario}`, "YYYY-MM-DD HH:mm");
        return horarioDateTime.isAfter(now);
      });
    });

    // Filtra os clientes com base na busca
    const filteredClients = computed(() => {
      const query = searchQuery.value.toLowerCase().trim();
      return clients.value.filter((client) =>
        client.nome.toLowerCase().includes(query) || client.email.toLowerCase().includes(query)
      );
    });

    // Formata a data para exibição
    const formatDate = (date: string) => {
      return dayjs(date).format("DD/MM/YYYY");
    };

    // Fecha o dropdown ao clicar fora
    const handleOutsideClick = (event: MouseEvent) => {
      const target = event.target as HTMLElement;
      if (!target.closest(".search-section")) {
        showDropdown.value = false;
      }
    };

    return {
      searchQuery,
      clients,
      filteredClients,
      loadingClients,
      loadingDetails,
      showDropdown,
      selectedClient,
      filteredHorarios,
      onInput,
      selectClient,
      formatDate,
      handleOutsideClick,
    };
  },
});
</script>

<style scoped>
.search-clients-page {
  padding: 20px;
  min-height: 100vh;
  background: linear-gradient(135deg, #FF6A00 0%, #f01f84 100%);
  display: flex;
  justify-content: center;
  align-items: center;
}

.container {
  padding: 30px;
  border-radius: 10px;
  max-width: 800px;
  width: 100%;
  margin: 0 auto;
  text-align: center;
  background: rgba(255, 255, 255, 0.8);
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.15);
}

h3 {
  font-size: 1.8rem;
  margin-bottom: 20px;
  text-transform: uppercase;
  color: #333;
}

.search-section {
  position: relative;
  width: 100%;
  margin-bottom: 30px;
}

.search-input {
  width: 100%;
  padding: 12px;
  border: 1px solid #ddd;
  border-radius: 8px;
  font-size: 1rem;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
}

.client-dropdown {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  max-height: 250px;
  overflow-y: auto;
  background: white;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  padding: 0;
  margin: 5px 0 0;
  z-index: 10;
  list-style: none;
}

.client-dropdown li {
  padding: 10px;
  border-bottom: 1px solid #eee;
  cursor: pointer;
}

.client-dropdown li:hover {
  background: #f0f0f0;
}

.no-results {
  margin-top: 10px;
  color: #888;
}

.client-details {
  margin-top: 20px;
  text-align: left;
}

.client-details h4 {
  margin-bottom: 10px;
  font-weight: bold;
  font-size: 1.5rem;
}

.client-details p {
  margin: 5px 0;
}

.client-section {
  margin-bottom: 20px;
}

.client-section h5 {
  margin-bottom: 10px;
  font-weight: bold;
  font-size: 1.2rem;
}

.empty-info {
  color: #888;
  font-style: italic;
  margin-top: 10px;
}

.client-details ul {
  padding-left: 20px;
  margin: 5px 0 20px;
}

/* Loader Centralizado */
.loader-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.loader {
  margin-top: 10px;
  margin-bottom: 5px;
  width: 50px;
  height: 50px;
  border: 8px solid #f3f3f3;
  border-radius: 50%;
  border-top: 8px solid #3498db;
  animation: spin 1s linear infinite;
}

.loader-container p {
  font-size: 1.2rem;
  color: #000;
  text-align: center;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* Responsividade */
@media (max-width: 768px) {
  .container {
    padding: 20px;
  }

  h3 {
    font-size: 1.5rem;
  }

  .search-input {
    padding: 10px;
    font-size: 0.95rem;
  }

  .client-details h4 {
    font-size: 1.3rem;
  }
}
</style>
