<template>
  <div class="success-page" v-if="loading">
    <h1>Verificando o status do pagamento...</h1>
  </div>
  <div class="success-page" v-else>
    <h1>{{ message }}</h1>
    <p v-if="paymentSuccessful">Sua reserva foi confirmada.</p>
    <p v-else>Houve um problema ao confirmar sua reserva. Tente novamente mais tarde.</p>
    <button @click="goToHome">Voltar para a Home</button>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, onMounted } from 'vue';
import { useRouter } from 'vue-router';
import axios from 'axios';

export default defineComponent({
  name: 'SuccessPage',
  setup() {
    const router = useRouter();
    const paymentSuccessful = ref(false);
    const message = ref('Verificando o status do pagamento...');
    const loading = ref(true);

    const checkPaymentStatus = async (sessionId: string) => {
      try {
        const response = await axios.post(`${process.env.VUE_APP_BACKEND_URL}/stripe/confirm-payment`, { sessionId });
        if (response.data.success) {
          paymentSuccessful.value = true;
          message.value = 'Pagamento realizado com sucesso!';
        } else {
          message.value = 'Erro ao confirmar pagamento.';
        }
      } catch (error) {
        console.error('Erro ao verificar status do pagamento:', error);
        message.value = 'Erro ao verificar o status do pagamento.';
      } finally {
        loading.value = false;
      }
    };

    onMounted(() => {
      const sessionId = new URLSearchParams(window.location.search).get('session_id');
      if (sessionId) {
        checkPaymentStatus(sessionId);
      } else {
        message.value = 'ID da sessão de pagamento não encontrado.';
        loading.value = false;
      }
    });

    const goToHome = () => {
      router.push('/');
    };

    return {
      paymentSuccessful,
      message,
      loading,
      goToHome,
    };
  },
});
</script>

<style scoped>
.success-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background: linear-gradient(135deg, #FF6A00 0%, #f01f84 100%);
  color: white;
  text-align: center;
}

button {
  padding: 10px 20px;
  background-color: white;
  color: #ff5858;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1rem;
  transition: background-color 0.3s;
}

button:hover {
  background-color: #f857a6;
}
</style>
