<template>
  <div class="cancel-page">
    <h1>Pagamento Cancelado</h1>
    <!-- Exibe a mensagem personalizada baseada no motivo do cancelamento -->
    <p>{{ message }}</p>
    <router-link to="/">Voltar à página inicial</router-link>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { useRoute } from 'vue-router';

export default defineComponent({
  name: 'CancelPage',
  setup() {
    const route = useRoute(); // Captura a rota atual
    const reason = route.query.reason; // Pega o parâmetro 'reason' da URL

    // Define a mensagem com base no motivo do cancelamento
    const message = reason === 'conflito'
      ? 'Esse horário já está reservado. Por favor, escolha outro horário.'
      : 'Seu pagamento foi cancelado. Se você tiver alguma dúvida, entre em contato com o suporte.';

    return {
      message
    };
  }
});
</script>

<style scoped>
.cancel-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background: linear-gradient(135deg, #FF6A00 0%, #f01f84 100%);
  color: #ffffff;
  padding: 20px;
  text-align: center;
}

.cancel-page h1 {
  font-size: 2em;
  margin-bottom: 20px;
}

.cancel-page p {
  margin-bottom: 20px;
}

.cancel-page a {
  color: #ffffff;
  text-decoration: none;
  font-weight: bold;
}
</style>
