<template>
  <div class="reset-password-container">
    <div v-if="!tokenExpired">
      <form @submit.prevent="resetPassword">
        <div class="form-group">
          <h1>Redefinir Senha</h1>
          <div v-if="loading" class="loader-container">
        <div class="loader"></div>
        <p>Redefinindo senha...</p>
      </div>
      <div v-else>
          <label for="newPassword">Nova Senha</label>
          <input type="password" id="newPassword" v-model="newPassword" required />
        <button type="submit">Definir nova senha</button>
      </div>
      </div>
      </form>
    </div>
    <div v-else class="token-expired">
      <p>O token expirou. Por favor, solicite uma nova redefinição de senha.</p>
      <router-link to="/login">Voltar para o login</router-link>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, onMounted } from 'vue';
import axios from 'axios';
import { useRouter } from 'vue-router';
import { useRoute } from 'vue-router';
import jwtDecode from 'jwt-decode';

export default defineComponent({
  name: 'ResetPassword',
  setup() {
    const newPassword = ref('');
    const loading = ref(false); // Loader status
    const tokenExpired = ref(false);
    const token = ref('');
    const router = useRouter();
    const route = useRoute();

    const resetPassword = async () => {
      loading.value = true;
      try {
        await axios.post(`${process.env.VUE_APP_BACKEND_URL}/reset-password`, {
          token: token.value,
          newPassword: newPassword.value,
        });
        alert('Senha redefinida com sucesso!');
        router.push('/login'); 
      } catch (error) {
        console.error('Erro ao redefinir a senha:', error);
        alert('Erro ao redefinir a senha. Por favor, tente novamente.');
      } finally {
        loading.value = false;
      }
    };

    const verifyToken = () => {
      token.value = (route.params.token as string) || '';

      if (!token.value) {
        tokenExpired.value = true;
        return;
      }

      try {
        const decoded: { exp: number } = jwtDecode(token.value);
        const currentTime = Math.floor(Date.now() / 1000);
        if (decoded.exp < currentTime) {
          tokenExpired.value = true;
        }
      } catch (error) {
        console.error('Token inválido:', error);
        tokenExpired.value = true;
      }
    };

    onMounted(() => {
      verifyToken();
    });

    return {
      newPassword,
      resetPassword,
      loading,
      tokenExpired,
    };
  },
});
</script>

<style scoped>
.reset-password-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background: linear-gradient(135deg, #FF6A00 0%, #f01f84 100%);
  color: white;
  padding: 40px 20px;
}

.reset-password-container h1 {
  font-size: 28px;
  text-align: center;
  color: #f857a6;
}

form {
  background: white;
  padding: 40px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  text-align: center;
  width: 100%;
  max-width: 400px;
}

.form-group {
  margin-bottom: 1rem;
  text-align: left;
}

label {
  display: block;
  margin-bottom: 0.5rem;
  font-weight: 700;
}

input {
  width: 100%;
  padding: 0.75rem;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 1rem;
}

button {
  background-color: #f857a6;
  color: white;
  border: none;
  padding: 0.75rem;
  font-size: 16px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
  width: 100%;
  margin-top: 1rem;
  font-weight: bold;
}

button:hover:not(:disabled) {
  background-color: #ff5858;
}

/* Loader Centralizado */
.loader-container {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.loader {
  border: 16px solid #f3f3f3; 
  border-top: 16px solid #3498db; 
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite;
  margin: auto;
}

.loader-container p {
  font-size: 1.3rem; /* Texto mais proporcional */
  color: #000;
  margin-top: 10px;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.token-expired {
  background: white;
  padding: 40px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  text-align: center;
  width: 100%;
  max-width: 400px;
  color: #333;
}

.token-expired p {
  margin-bottom: 1.5rem;
  font-size: 18px;
}

.router-link {
  color: #f857a6;
  text-decoration: none;
}

.router-link:hover {
  text-decoration: underline;
}
</style>
