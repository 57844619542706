<template>
  <div class="icon-container">
    <!-- Link para a prop `to` configurável -->
    <router-link v-if="showArrow" :to="to" class="arrow-link">
      <font-awesome-icon :icon="['fas', 'arrow-left']" class="arrow-icon" />
    </router-link>

    <!-- Link fixo para a rota raiz `/` -->
    <router-link to="/" class="home-link">
      <font-awesome-icon :icon="['fas', 'home']" class="home-icon" />
    </router-link>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

export default defineComponent({
  name: 'HomeIcon',
  components: {
    FontAwesomeIcon,
  },
  props: {
    to: {
      type: String,
      default: '/',
    },
    showArrow: {
      type: Boolean,
      default: false,
    },
  },
});
</script>

<style scoped>
.icon-container {
  position: absolute;
  top: 20px;
  left: 20px;
  display: flex;
  align-items: center;
  cursor: pointer;
}

/* Estilos do ícone de home */
.home-icon {
  font-size: 24px;
  color: white;
  transition: color 0.3s ease;
}

.home-icon:hover {
  color: #ffc0cb; /* Cor rosada exclusiva para o ícone de home */
}

/* Estilos da seta */
.arrow-icon {
  font-size: 24px;
  color: white;
  margin-right: 8px;
  transition: color 0.3s ease;
}

.arrow-icon:hover {
  color: #ffb6c1; /* Cor rosada exclusiva para a seta */
}
</style>
