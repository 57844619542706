import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-40b2b0b9"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "calendar-view" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_calendar = _resolveComponent("v-calendar")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_v_calendar, {
      style: { width: '700px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', border: '2px solid #ccc', borderRadius: '10px' },
      "is-expanded": "",
      attributes: _ctx.attrs,
      "min-date": _ctx.today,
      "max-date": _ctx.maxDate,
      onDayclick: _ctx.onDayClick
    }, null, 8, ["attributes", "min-date", "max-date", "onDayclick"])
  ]))
}