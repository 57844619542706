<template>
    <div class="terms-of-service-container">
      <h1>Termos de Uso</h1>
      <div class="content">
        <section>
          <h2>1. Introdução</h2>
          <p>Estes Termos de Uso regem o uso do nosso serviço. Ao acessar ou usar nosso serviço, você concorda em cumprir estes Termos.</p>
        </section>
        <section>
          <h2>2. Aceitação dos Termos</h2>
          <p>Ao se cadastrar e usar nossa plataforma, você aceita e concorda com estes Termos de Uso.</p>
        </section>
        <section>
          <h2>3. Uso do Serviço</h2>
          <p>Você concorda em usar nosso serviço de forma responsável e em conformidade com todas as leis aplicáveis. Você não pode usar nosso serviço para qualquer finalidade ilegal ou não autorizada.</p>
        </section>
        <section>
          <h2>4. Contas de Usuário</h2>
          <p>Você é responsável por manter a confidencialidade de sua conta e senha e por restringir o acesso ao seu computador. Você concorda em aceitar a responsabilidade por todas as atividades que ocorrem em sua conta.</p>
        </section>
        <section>
          <h2>5. Limitações de Responsabilidade</h2>
          <p>Não seremos responsáveis por quaisquer danos diretos, indiretos, incidentais, especiais, consequenciais ou exemplares, incluindo, mas não se limitando a, danos por perda de lucros, boa vontade, uso, dados ou outras perdas intangíveis.</p>
        </section>
        <section>
          <h2>6. Modificações dos Termos</h2>
          <p>Podemos modificar estes Termos de Uso a qualquer momento. As modificações entrarão em vigor imediatamente após a publicação dos Termos de Uso atualizados em nossa plataforma.</p>
        </section>
        <section>
          <h2>7. Contato</h2>
          <p>Se você tiver alguma dúvida sobre estes Termos de Uso, entre em contato conosco através do email: suporte@exemplo.com</p>
        </section>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: 'TermsOfService'
  };
  </script>
  
  <style scoped>
  .terms-of-service-container {
    padding: 20px;
    max-width: 800px;
    margin: auto;
  }
  
  h1, h2 {
    color: #333;
  }
  
  p, ul {
    font-size: 16px;
    line-height: 1.6;
  }
  
  section {
    margin-bottom: 20px;
  }
  </style>
  