<template>
  <div class="select-time-page">
    <HomeIcon :showArrow="true" to="/calendar" />
    <div class="container">
      <div class="loader-container" v-if="loading">
        <div class="loader"></div>
        <p>Carregando dados...</p>
      </div>
      <div v-else>
        <div v-if="showPaymentWarning" class="payment-warning-overlay">
          <div class="payment-warning-box">
            <h3>Atenção!</h3>
            <p>
              Você tem 5 minutos para concluir o pagamento. Após esse tempo, sua sessão será expirada e os horários selecionados ficarão indisponíveis para pagamento.
            </p>
            <div class="payment-warning-buttons">
              <button @click="cancelPayment" class="cancel-button">Cancelar</button>
              <button @click="confirmPayment" class="continue-button">Continuar</button>
            </div>
          </div>
        </div>
        <div v-if="hasSelectedDates" :class="{ 'blurred': showPaymentWarning }">
          <h1 class="title">Selecione seus horários para {{ formattedDate }}</h1>
          <div class="main-content">
            <h2>Horários disponíveis:</h2>
            <div v-for="court in sortedCourts" :key="court.id" class="court">
              <h3>{{ court.nome }} - {{ court.patrocinador.nome }}</h3>
              <div class="times">
                <button
                  v-for="time in availableTimes[court.id]"
                  :key="time.hour"
                  :class="{
                    disabled: !time.available,
                    selected: isSelected(court.id, time.hour),
                  }"
                  @click="selectTime(court.id, time.hour)"
                  :disabled="!time.available"
                >
                  {{ time.hour }}
                </button>
              </div>
            </div>
            <p class="description">
              É possível selecionar mais de um horário de uma vez, em diferentes quadras.
            </p>
            <div class="total-price">
              Valor total: R$ {{ totalPrice.toFixed(2) }}
            </div>
            <p v-if="discountApplied" class="discount-message">
              Desconto aplicado por ter plano: {{ activePlanName }}
            </p>
            <button
              class="next-button"
              :class="{ clicked: nextButtonClicked }"
              @click="showPaymentWarning = true"
              @mousedown="nextButtonClicked = true"
              @mouseup="nextButtonClicked = false"
            >
              Próximo
            </button>
            <p v-if="showWarning" class="warning">
              Por favor, selecione um horário antes de prosseguir.
            </p>
            <p v-if="errorMessage" class="error">{{ errorMessage }}</p>
          </div>
        </div>
        <div v-else>
          <WarningData :rota="rota" />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, computed, onMounted } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import dayjs from "dayjs";
import { loadStripe } from "@stripe/stripe-js";
import HomeIcon from "../../components/HomeIcon.vue";
import WarningData from "../../components/WarningData.vue";
import axios, { AxiosError } from "axios";

const stripePromise = loadStripe("pk_test_51PfufkRxlLJdQ9IhESSmTptbyyFlrIbQbBA8KComvvRgFSxKt9IUh9DmqkBIOOgx0rDGweY00GDohnCL1id9KfDH00Xgsu33QH");

interface AvailableTime {
  hour: string;
  available: boolean;
}

interface Court {
  id: number;
  nome: string;
  patrocinador: {
    nome: string;
  };
}

export default defineComponent({
  name: "SelectTimePage",
  components: {
    HomeIcon,
    WarningData,
  },
  setup() {
    const BASE_PRICE_PER_HOUR = 80;
    const PRICE_DISCOUNTS = {
      mensal: 75,
      trimestral: 65,
    };

    const rota = ref<string>("calendar");
    const store = useStore();
    const router = useRouter();
    const courts = ref<Court[]>([]);
    const availableTimes = ref<{ [courtId: number]: AvailableTime[] }>({});
    const selectedTimes = ref<{ court: number; hour: string }[]>([]);
    const loading = ref(true);
    const nextButtonClicked = ref(false);
    const showWarning = ref(false);
    const errorMessage = ref<string | null>(null);
    const activePlanName = ref<string | null>(null);
    const showPaymentWarning = ref(false);

    const hasSelectedDates = computed(() => store.state.selectedDate != null);
    const selectedDate = computed(() => dayjs(store.state.selectedDate));
    const formattedDate = computed(() =>
      selectedDate.value.format("DD/MM/YYYY")
    );
    const usuario = computed(() => store.state.usuario);

    // Função para ordenar as quadras por número
    const sortedCourts = computed(() => {
      return courts.value.slice().sort((a, b) => {
        const numberA = parseInt(a.nome.match(/\d+/)?.[0] || "0");
        const numberB = parseInt(b.nome.match(/\d+/)?.[0] || "0");
        return numberA - numberB;
      });
    });

    const fetchCourts = async () => {
      try {
        const response = await axios.get(`${process.env.VUE_APP_BACKEND_URL}/quadras`);
        courts.value = response.data;
      } catch (error) {
        console.error("Error fetching courts:", error);
      }
    };

    const fetchOccupiedTimes = async (date: string, courtId: number) => {
  try {
    const response = await axios.get(
      `${process.env.VUE_APP_BACKEND_URL}/horariosOcupados/${date}/${courtId}`
    );
    // Extrai apenas os horários da resposta
    return response.data.map((item: { horario: string }) => item.horario);
  } catch (error) {
    console.error("Error fetching occupied times:", error);
    return [];
  }
};


    const generateAvailableTimes = async () => {
      const times: AvailableTime[] = [];
      const now = dayjs();

      for (let hour = 7; hour <= 21; hour++) {
        const time = selectedDate.value.hour(hour).minute(0).second(0);
        const isAvailable =
          selectedDate.value.isAfter(now, "day") || time.isAfter(now);

        times.push({
          hour: time.format("HH:mm"),
          available: isAvailable,
        });
      }

      for (const court of courts.value) {
        const occupiedTimes = await fetchOccupiedTimes(
          selectedDate.value.format("YYYY-MM-DD"),
          court.id
        );
        const courtTimes = times.map((time) => ({
          ...time,
          available: time.available && !occupiedTimes.includes(time.hour),
        }));
        availableTimes.value[court.id] = courtTimes;
      }

      loading.value = false;
    };

    const selectTime = (court: number, hour: string) => {
      const index = selectedTimes.value.findIndex(
        (t) => t.court === court && t.hour === hour
      );
      if (index === -1) {
        selectedTimes.value.push({ court, hour });
      } else {
        selectedTimes.value.splice(index, 1);
      }
    };

    const isSelected = (court: number, hour: string) => {
      return selectedTimes.value.some(
        (t) => t.court === court && t.hour === hour
      );
    };

    const fetchUserPlans = async () => {
      try {
        const response = await axios.get(
          `${process.env.VUE_APP_BACKEND_URL}/${usuario.value?.id}`
        );
        const userPlans = response.data || [];
        if (userPlans.some((plan: any) => plan.nome === "trimestral")) {
          activePlanName.value = "Trimestral";
        } else if (userPlans.some((plan: any) => plan.nome === "mensal")) {
          activePlanName.value = "Mensal";
        }
      } catch (error) {
        console.error("Erro ao obter planos do usuário:", error);
      }
    };

    const goToNextStep = async () => {
      loading.value = true;
      const selectedEntries = selectedTimes.value.map((t) => ({
        court: t.court,
        hour: t.hour,
      }));

      await store.dispatch("fetchUsuario");
      const userId = store.state.usuario?.id;

      if (!userId) {
        errorMessage.value = "User ID is missing";
        loading.value = false;
        return;
      }

      try {
        const response = await axios.post(
          `${process.env.VUE_APP_BACKEND_URL}/stripe/create-checkout-session`,
          {
            date: selectedDate.value.format("YYYY-MM-DD"),
            entries: selectedEntries,
            userId,
          }
        );

        const sessionId = response.data.id;

        const stripe = await stripePromise;
        if (stripe) {
          stripe.redirectToCheckout({ sessionId });
        } else {
          console.error("Stripe.js failed to load.");
        }
      } catch (error) {
        const axiosError = error as AxiosError;
        if (
          axiosError.response &&
          axiosError.response.data &&
          (axiosError.response.data as any).error
        ) {
          errorMessage.value = (axiosError.response.data as any).error;
        } else {
          console.error("Error creating checkout session:", axiosError);
        }
      } finally {
        loading.value = false;
      }
    };

    const cancelPayment = () => {
      showPaymentWarning.value = false;
    };

    const confirmPayment = () => {
      showPaymentWarning.value = false;
      goToNextStep();
    };

    onMounted(async () => {
      await store.dispatch("fetchUsuario");

      if (hasSelectedDates.value) {
        await fetchCourts();
        await generateAvailableTimes();
        await fetchUserPlans();
      }
      loading.value = false;
    });

    const totalPrice = computed(() => {
      let pricePerHour = BASE_PRICE_PER_HOUR;

      if (activePlanName.value === "Trimestral") {
        pricePerHour = PRICE_DISCOUNTS.trimestral;
      } else if (activePlanName.value === "Mensal") {
        pricePerHour = PRICE_DISCOUNTS.mensal;
      }

      return selectedTimes.value.length * pricePerHour;
    });

    const discountApplied = computed(() => {
      return activePlanName.value !== null;
    });

    return {
      hasSelectedDates,
      formattedDate,
      availableTimes,
      sortedCourts,
      selectTime,
      isSelected,
      goToNextStep,
      loading,
      nextButtonClicked,
      showWarning,
      rota,
      errorMessage,
      totalPrice,
      discountApplied,
      activePlanName,
      showPaymentWarning,
      cancelPayment,
      confirmPayment,
    };
  },
});
</script>

<style scoped>

.select-time-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  background: linear-gradient(135deg, #FF6A00 0%, #f01f84 100%);
  color: white;
  padding: 40px 20px;
}

.main-content {
  border-top: 5px solid #cd7f32;
  background: white;
  color: #333;
  width: 100%;
  padding: 30px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  max-width: 1350px;
}

.court {
  margin-top: 10px;
  padding: 15px;
  background-color: #f7f7f7;
  border-radius: 8px;
}

.title {
  text-transform: uppercase;
  text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.3);
}

.times {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 10px;
}

button {
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

button:hover:not(.disabled) {
  background-color: #f857a6;
}

button.disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

button:not(.disabled) {
  background-color: #ff5858;
  color: white;
}

button.selected {
  background-color: #4300a2;
  color: white;
}

button.selected:hover {
  background-color: #6600f5;
}

.description {
  margin-top: 20px;
  color: #666;
}

.total-price {
  font-size: 1.2rem;
  font-weight: bold;
  margin-top: 20px;
  color: #333;
}

.discount-message {
  font-size: 1rem;
  color: green;
  margin-top: 10px;
}

.next-button {
  background: linear-gradient(135deg, #FF6A00 0%, #f01f84 100%);
  border: none;
  color: white;
  padding: 10px 20px;
  font-size: 1rem;
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  letter-spacing: 1px;
  text-transform: uppercase;
  cursor: pointer;
  border-radius: 5px;
  transition: background 0.3s;
  margin-top: 20px;
}

.next-button.clicked {
  background-color: #007bff;
  color: white;
}

.next-button.clicked:hover {
  background-color: #0056b3;
}

.next-button:hover {
  background: linear-gradient(135deg, #f01f84 0%, #FF6A00 100%);
}

.loader-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.loader {
  border: 16px solid #f3f3f3;
  border-top: 16px solid #3498db;
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite;
  margin: auto;
}

.loader-container p { 
  font-size: 1.5rem;
  margin-top: 10px;
  color: #ffffff;
  text-align: center; 
}

.error {
  color: #ff0000;
  font-weight: bold;
  margin-top: 20px;
  text-align: center;
  padding: 10px;
  background-color: #ffe6e6;
  border: 1px solid #ff0000;
  border-radius: 5px;
}

.warning {
  color: red;
  font-weight: bold;
  margin-top: 10px;
}

.payment-warning-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.payment-warning-box {
  color: black;
  background-color: white;
  padding: 30px;
  border-radius: 10px;
  text-align: center;
  max-width: 500px;
  width: 100%;
}

.payment-warning-box h3 {
  font-size: 1.5rem;
  margin-bottom: 20px;
}

.payment-warning-box p {
  font-size: 1rem;
  margin-bottom: 30px;
}

.payment-warning-buttons {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.cancel-button,
.continue-button {
  padding: 15px;
  border: none;
  border-radius: 5px;
  font-size: 1rem;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s;
}

.cancel-button {
  background-color: #ff4c4c;
  color: white;
}

.continue-button {
  background-color: #4300a2 !important;
  color: white;
}

.cancel-button:hover {
  background-color: #ff3333;
}

.continue-button:hover {
  background-color: #6600f5 !important;
}

.blurred {
  filter: blur(4px);
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
