<template>
    <div class="complete-registration-container">
      <HomeIcon />
      <div class="complete-registration-box">
        <h1>Complete seu Cadastro</h1>
        <form @submit.prevent="completeRegistration">
          <div class="form-group">
            <label for="nome">Nome</label>
            <input
              type="text"
              id="nome"
              v-model="state.nome"
              @blur="validateField('nome')"
            />
            <span v-if="state.errors.nome" class="error">{{ state.errors.nome }}</span>
          </div>
          <div class="form-group">
            <label for="cpf">CPF</label>
            <input
              type="text"
              id="cpf"
              v-model="state.cpf"
              @input="applyCpfMask"
              @blur="validateField('cpf')"
            />
            <span v-if="state.errors.cpf" class="error">{{ state.errors.cpf }}</span>
          </div>
          <div class="form-group">
            <label for="telefone">Telefone</label>
            <input
              type="tel"
              id="telefone"
              v-model="state.telefone"
              @input="applyPhoneMask"
              @blur="validateField('telefone')"
            />
            <span v-if="state.errors.telefone" class="error">{{ state.errors.telefone }}</span>
          </div>
          <div class="form-group">
            <label for="senha">Senha</label>
            <input
              type="password"
              id="senha"
              v-model="state.senha"
              @blur="validateField('senha')"
            />
            <span v-if="state.errors.senha" class="error">{{ state.errors.senha }}</span>
          </div>
          <button type="submit">Concluir Cadastro</button>
        </form>
      </div>
    </div>
  </template>
  
  <script lang="ts">
  import { defineComponent, reactive, onMounted } from 'vue';
  import axios from 'axios';
  import * as yup from 'yup';
  import { useRouter, useRoute } from 'vue-router';
  import HomeIcon from '../components/HomeIcon.vue';
  import { validateCPF } from '../services/validateCPF'; // Função para validar CPF
  
  export default defineComponent({
    name: 'ConfirmEmailPage',
    components: {
      HomeIcon,
    },
    setup() {
      const router = useRouter();
      const route = useRoute();
  
      const state = reactive({
        nome: '',
        cpf: '',
        telefone: '',
        senha: '',
        errors: {
          nome: '',
          cpf: '',
          telefone: '',
          senha: '',
        } as Record<string, string>,
      });
  
      const schema = yup.object().shape({
        nome: yup
          .string()
          .min(2, 'O nome deve ter pelo menos 2 caracteres')
          .required('Nome é obrigatório'),
        cpf: yup
          .string()
          .required('CPF é obrigatório')
          .test('is-valid-cpf', 'CPF inválido', (value) => {
            const cleanCpf = value?.replace(/\D/g, '');
            if (!cleanCpf || cleanCpf.length < 11) {
              return false;
            }
            return validateCPF(cleanCpf);
          }),
        telefone: yup
          .string()
          .matches(
            /^\(\d{2}\) \d{4,5}-\d{4}$/,
            'O telefone deve estar no formato (XX) XXXXX-XXXX'
          )
          .required('Telefone é obrigatório'),
        senha: yup
          .string()
          .min(8, 'A senha deve ter pelo menos 8 caracteres')
          .required('Senha é obrigatória'),
      });
  
      const validateField = async (field: string) => {
        try {
          await schema.validateAt(field, state);
          state.errors[field] = '';
        } catch (err) {
          if (err instanceof yup.ValidationError) {
            state.errors[field] = err.message;
          }
        }
      };
  
      const validate = async () => {
        try {
          await schema.validate({ ...state }, { abortEarly: false });
          state.errors = {
            nome: '',
            cpf: '',
            telefone: '',
            senha: '',
          };
          return true;
        } catch (err) {
          if (err instanceof yup.ValidationError) {
            const newErrors: Record<string, string> = {
              nome: '',
              cpf: '',
              telefone: '',
              senha: '',
            };
            err.inner.forEach((error) => {
              if (error.path) {
                newErrors[error.path] = error.message;
              }
            });
            state.errors = newErrors;
          }
          return false;
        }
      };
  
      const completeRegistration = async () => {
        if (!(await validate())) {
          return;
        }
  
        try {
          // Enviando o token, CPF, telefone e senha para completar o cadastro
          await axios.post(`${process.env.VUE_APP_BACKEND_URL}/complete-registration`, {
            token: route.query.token, // O token vem do link de confirmação
            nome: state.nome,
            cpf: state.cpf,
            telefone: state.telefone,
            senha: state.senha,
          });
  
          alert('Cadastro concluído com sucesso!');
          router.push('/login');
        } catch (error) {
          console.error('Erro ao completar cadastro:', error);
          alert('Erro ao completar cadastro. Por favor, tente novamente.');
        }
      };
  
      const applyCpfMask = (event: Event) => {
        const input = event.target as HTMLInputElement;
        let value = input.value.replace(/\D/g, '');
        if (value.length > 11) value = value.slice(0, 11);
        const cpfParts = value.match(/(\d{0,3})(\d{0,3})(\d{0,3})(\d{0,2})/);
        if (cpfParts) {
          input.value = !cpfParts[2]
            ? cpfParts[1]
            : `${cpfParts[1]}.${cpfParts[2]}${
                cpfParts[3] ? '.' + cpfParts[3] : ''
              }${cpfParts[4] ? '-' + cpfParts[4] : ''}`;
          state.cpf = input.value;
        }
      };
  
      const applyPhoneMask = (event: Event) => {
        const input = event.target as HTMLInputElement;
        let value = input.value.replace(/\D/g, '');
        if (value.length > 11) value = value.slice(0, 11);
        const phoneParts = value.match(/(\d{0,2})(\d{0,5})(\d{0,4})/);
        if (phoneParts) {
          input.value = !phoneParts[2]
            ? phoneParts[1]
            : `(${phoneParts[1]}) ${phoneParts[2]}${
                phoneParts[3] ? '-' + phoneParts[3] : ''
              }`;
          state.telefone = input.value;
        }
      };
  
      onMounted(async () => {
        try {
          const token = route.query.token as string;
          const response = await axios.get(
            `${process.env.VUE_APP_BACKEND_URL}/confirm-email`,
            { params: { token } }
          );
          state.nome = response.data.nome;
        } catch (error) {
          console.error('Erro ao validar o token:', error);
          alert('Erro ao validar o link. Ele pode estar expirado ou inválido.');
          router.push('/register'); // Redireciona se houver erro
        }
      });
  
      return {
        state,
        completeRegistration,
        validateField,
        validate,
        applyCpfMask,
        applyPhoneMask,
      };
    },
  });
  </script>
  
  <style scoped>
  .complete-registration-container {
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(135deg, #FF6A00, #f01f84);
  padding: 20px;
  min-height: 100vh;
}

.complete-registration-box {
  background: white;
  padding: 40px;
  border-radius: 10px;
  width: 100%;
  max-width: 500px;
  text-align: center;
}

h1 {
  font-size: 2rem;
  margin-bottom: 20px;
  color: #f857a6;
  font-weight: bold;
}

.form-group {
  margin-bottom: 20px;
  text-align: left;
}

label {
  display: block;
  margin-bottom: 8px;
  font-weight: bold;
  color: #333;
}

input {
  width: 100%;
  padding: 12px;
  border: 1px solid #ccc;
  border-radius: 8px;
  font-size: 1rem;
  color: #333;
  transition: border-color 0.3s ease;
}

input:focus {
  border-color: #f857a6;
  outline: none;
}

.error {
  color: red;
  font-size: 0.875rem;
  margin-top: 5px;
}

button {
  width: 100%;
  padding: 12px;
  font-size: 1.1rem;
  font-weight: bold;
  background-color: #f857a6;
  color: white;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  box-shadow: 0 2px 10px rgba(248, 87, 166, 0.3);
  transition: background-color 0.3s, transform 0.2s;
}

button:hover {
  background-color: #ff5858;
}

button:active {
  transform: translateY(2px);
}

/* Responsividade */
@media (max-width: 768px) {
  .complete-registration-box {
    padding: 20px;
  }

  h1 {
    font-size: 1.5rem;
  }

  input {
    padding: 10px;
    font-size: 0.95rem;
  }

  button {
    font-size: 1rem;
  }
}

  </style>
  