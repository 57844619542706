<template>
  <div class="container">
    <HomeIcon :showArrow="true" to="/planos" />
    <h1>ESCOLHA UM DIA</h1>
    <div class="main">
      <CalendarView />
      <div class="legend">
        <div class="legend-item">
          <span class="dot red"></span> <span>Hoje</span>
        </div>
        <div class="legend-item">
          <span class="dot blue"></span> <span>Selecionado pelo usuário</span>
        </div>
      </div>
      <div class="paragrafo">
      <p class="description">
        Para reservas avulsas, você só pode selecionar um dia por vez. Se deseja agendar em outros dias, finalize sua compra atual e volte a esta página para realizar outra reserva.
            </p>
          </div>
      <button class="next-button" @click="goToSelectTime">Próximo</button>
      <p v-if="showWarning" class="warning">Por favor, selecione uma data primeiro.</p>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import CalendarView from '../../components/Calendar.vue';
import HomeIcon from '../../components/HomeIcon.vue';

export default defineComponent({
  name: 'CalendarPage',
  components: {
    CalendarView,
    HomeIcon,
  },
  setup() {
    const router = useRouter();
    const store = useStore();
    const showWarning = ref(false);

    const goToSelectTime = () => {
      if (store.state.selectedDate === null) {
        showWarning.value = true;
      } else {
        router.push({ name: 'SelectTime' });
      }
    };

    return {
      goToSelectTime,
      showWarning,
    };
  },
});
</script>

<style scoped>
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background: linear-gradient(135deg, #FF6A00 0%, #f01f84 100%);
  position: relative;
}

.description {
  text-align: center;
  margin-top: 20px;
  color: #666;
}

.paragrafo {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  max-width: 80%;
  margin: 0 auto;
}

.main {
  border-top: 5px solid #cd7f32; /* Bronze */
  width: 100%;
  max-width: 1200px;
  padding: 20px;
  text-align: center;
  background: white;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

h1 {
    font-size: 2.5rem;
    margin-bottom: 20px;
    color: white;
    text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.3);
  }

.legend {
  display: flex;
  justify-content: center;
  gap: 20px;
}

.legend-item {
  display: flex;
  align-items: center;
}

.dot {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  margin-right: 8px;
}

.dot.red {
  background-color: red;
}

.dot.blue {
  background-color: blue;
}

.next-button {
  background: linear-gradient(135deg, #FF6A00 0%, #f01f84 100%);
  border: none;
  color: white;
  padding: 10px 20px;
  font-size: 1rem;
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
  letter-spacing: 1px;
  text-transform: uppercase;
  cursor: pointer;
  border-radius: 5px;
  transition: background 0.3s;
  margin-top: 20px;
}

.next-button:hover {
  background: linear-gradient(135deg, #f01f84 0%, #FF6A00 100%);
}

.warning {
  color: red;
  margin-top: 10px;
  font-weight: bold;
}
</style>
