<template>
  <div class="forgot-password-container">
    <HomeIcon/>
    <div class="forgot-password-box">
      <h1>Redefinir Senha</h1>
      <!-- Loader enquanto o formulário é processado -->
      <div v-if="loading" class="loader-container">
        <div class="loader"></div>
        <p>Enviando dados...</p>
      </div>
      <!-- Formulário de redefinição de senha -->
      <form v-else @submit.prevent="handleForgotPassword">
        <div class="form-group">
          <label for="email">Email</label>
          <input type="email" id="email" v-model="email" required />
        </div>
        <button type="submit">Enviar Link de Redefinição</button>
      </form>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue';
import axios from 'axios';
import HomeIcon from '../components/HomeIcon.vue';

export default defineComponent({
  name: 'ForgotPassword',
  components: {
    HomeIcon
  },
  setup() {
    const email = ref('');
    const loading = ref(false);

    const handleForgotPassword = async () => {
      loading.value = true;
      try {
        await axios.post(`${process.env.VUE_APP_BACKEND_URL}/request-password-reset`, { email: email.value });
        alert('Email de redefinição de senha enviado. Verifique sua caixa de entrada.');
      } catch (error: any) {
        if (error.response && error.response.status === 404) {
          alert('O email informado não está cadastrado no sistema. Por favor, verifique se digitou corretamente ou cadastre-se.');
        } else {
          alert('Erro ao enviar o email de redefinição de senha. Por favor, tente novamente.');
        }
        console.error(error);
      } finally {
        loading.value = false;
      }
    };

    return { email, loading, handleForgotPassword };
  },
});
</script>

<style scoped>
.forgot-password-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background: linear-gradient(135deg, #FF6A00 0%, #f01f84 100%);
}

.forgot-password-box {
  background: white;
  padding: 60px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  text-align: center;
  width: 400px;
}

h1 {
  margin-bottom: 1.5rem;
  font-size: 28px;
  color: #f857a6;
}

.form-group {
  margin-bottom: 1rem;
  text-align: left;
}

label {
  display: block;
  margin-bottom: 0.5rem;
  font-weight: 700;
}

input {
  width: 100%;
  padding: 0.75rem;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 1rem;
}

button {
  background-color: #f857a6;
  color: white;
  border: none;
  padding: 0.75rem;
  font-size: 16px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
  width: 100%;
  margin-top: 1rem;
  font-weight: bold;
}

button:hover:not(:disabled) {
  background-color: #ff5858;
}

.loader-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.loader {
  border: 16px solid #f3f3f3; 
  border-top: 16px solid #3498db; 
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite;
  margin: auto;
}

.loader-container p {
  margin-top: 10px;
  font-size: 1.3rem;
  color: #000;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* Responsividade */
@media (max-width: 768px) {
  .forgot-password-box {
    width: 100%;
    padding: 40px;
  }

  h1 {
    font-size: 24px;
  }

  .loader-container p {
    font-size: 1.2rem;
  }
}
</style>
